import { Injectable } from "@angular/core";
import { formatDate } from "@angular/common";
import { Observable } from "rxjs/Observable";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { User } from "../models/auth.models";
import { CookieService } from "ngx-cookie-service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  readonly APIUrl = "https://dashboardserver.multiconversion.com";
  constructor(private http: HttpClient, private cookieService: CookieService) {}

  // http options used for making API calls
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  user: User;

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  public logina(email: string, password: string): Observable<any> {
    // this.http
    //   .post(
    //     this.APIUrl + "/api/auth/signin",
    //     JSON.stringify({ email, password }),
    //     {headers : new HttpHeaders({ 'Content-Type': 'application/json' })}
    //   )
    //   .subscribe(
    //     (data) => {
    //       //this.updateData(data["token"]);
    //       return data
    //     },
    //     (err) => {
    //       //this.errors = err["error"];
    //     }
    //   ); this.user this.user this.user

    return this.http.post(
      this.APIUrl + "/api/auth/signin/",
      JSON.stringify({ email, password }),
      this.httpOptions
    );
  }

  //   constructor() {
  //   }

  /**
   * Returns the current user
   */
  public currentUser() {
    if (this.cookieService.get("currentUser"))
      return JSON.parse(this.cookieService.get("currentUser"));
    else return false;
  }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string) {
    return "";
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   * @param username username
   * @param codeClient codeClient
   */
  register(
    username: string,
    email: string,
    password: string,
    codeClient: string
  ): Observable<any> {
    return this.http.post(
      this.APIUrl + "/api/auth/signup",
      JSON.stringify({ username, email, password, codeClient }),
      this.httpOptions
    );
  }

  // register(email: string, password: string) {
  //   return getFirebaseBackend()
  //     .registerUser(email, password)
  //     .then((response: any) => {
  //       const user = response;
  //       return user;
  //     });
  // }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(email: string) {
    return "";
  }

  /**
   * Logout the user
   */
  logout() {
    // logout the user
    if (this.cookieService.get("currentUser")) {
      this.cookieService.delete("currentUser");
      if (this.cookieService.get("accessToken")) {
        this.cookieService.delete("accessToken");
      }
      return true;
    }
    return false;
  }

  public get_test() {
    return this.http.get(this.APIUrl + "/get/token/", {
      headers: { "X-access-token": this.cookieService.get("accessToken") },
    });
  }
}
