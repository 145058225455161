import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.DASHBOARDS.TEXT",
    icon: "bx-desktop",
    link: "/dashboard",
  },
  {
    id: 2,
    label: "MENUITEMS.BUDGET.TEXT",
    icon: "bx-euro",
    link: "/budget/list",
  },
  {
    id: 3,
    label: "MENUITEMS.CREATECAMPAIGNS.TEXT",
    icon: "bxs-file-plus",
    // link: "/campaigns/create",
    subItems: [
      {
        id: 4,
        label: "MENUITEMS.CREATECAMPAIGNS.LIST.GOOGLE.TEXT",
        link: "/campaigns/google",
        parentId: 3,
      },
      {
        id: 5,
        label: "MENUITEMS.CREATECAMPAIGNS.LIST.FACEBOOK.TEXT",
        link: "/campaigns/facebook",
        parentId: 3,
      },
    ],
  },
  {
    id: 6,
    label: "MENUITEMS.REPORTS.TEXT",
    icon: "bx-spreadsheet",
    link: "/reports/list",
  },
  {
    id: 7,
    label: "MENUITEMS.OPTIMIZATION.TEXT",
    icon: "bx-loader-alt",
    link: "/optimization",
  },
  {
    id: 8,
    label: "MENUITEMS.CRM.TEXT",
    icon: "bx-sidebar",
    link: "/crm",
  },
  {
    id: 9,
    label: "MENUITEMS.EMAILSMSCAMPAIGNS.TEXT",
    icon: "bx-mail-send",
    link: "/eas-campaigns",
  },
  {
    id: 10,
    label: "MENUITEMS.INTEGRATION.TEXT",
    icon: "bx-extension",
    link: "/integration",
  },
  {
    id: 11,
    label: "MENUITEMS.MULTICANAL.TEXT",
    icon: "bx-share-alt",
    link: "/multicanal",
  }
];
